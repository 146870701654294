<template>
  <div>
    <div v-if="parkings.length" class="form-container">
      <div class="bg-shade--gray pb-2 mb-4">
        <div class="bg-orange--grad guest-form-header">
          <div class="text-title --white text-center font-weight-bold pt-3 pb-3">
            {{
              change ?
                $t("reservationAndUsageHistory.changeParkingLotChange") :
                $t("reservationAndUsageHistory.changeParkingLot")
            }}
          </div>
        </div>
        <!--  -->
        <div class="px-4 mb-4">

          <!--Parking use or not BEGIN-->
          <v-radio-group
            hide-details
            v-model="borrow"
            @change="toggleBorrow"
          >
            <v-container>
              <v-row>
                <v-col
                  class="mr-1 pa-0"
                  v-for="item in parkingRadio"
                  :key="item.value"
                >
                  <label
                    class="radio-border d-flex pa-2"
                  >
                    <v-radio
                      class="px-0"
                      :value="item.value"
                    ></v-radio>
                    <div class="d-flex flex-column">
                      <div class="text-medium">
                        {{ $t('reservationAndUsageHistory.parkingLot') }}
                      </div>
                      <h1 class="text-medium--c-prm">
                        {{ item.use }}
                      </h1>
                    </div>
                  </label>
                </v-col>
                <v-col cols="2" class="px-0 d-flex flex-column justify-center">
                  <span class="text-small text-center --white px-0 bg-pink rounded-4 py-1 mx-2">
                    {{ $t('reservationAndUsageHistory.mandatory') }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-radio-group>
          <!--Parking use or not END-->
          <template v-if="borrow">
            <template v-if="isParkingManaged" class="pb-3">
              <div v-for="(item, ind) in value" :key="ind">
                <!--              <div class="bg-smoke text-medium font-weight-bold &#45;&#45;ms pa-2 mt-4">-->
                <!--                {{ $t("reservationAndUsageHistory.car") }}{{ ind + 1 }}-->
                <!--              </div>-->

                <!--Number and colour BEGIN-->
                <v-container class="mt-3">
                  <v-row>
                    <v-col class="px-0">
                      {{ $t("reservationAndUsageHistory.number") }}
                      <v-text-field
                        class="pb-0 rounded-lg"
                        hide-details
                        solo
                        flat
                        outlined
                        :value="item.carNumber"
                        @input="(v) => changeParking(ind, { carNumber: v })"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-0">
                      {{ $t("reservationAndUsageHistory.colour") }}
                      <v-menu>
                        <template v-slot:activator="{on}">
                          <div class="color-picker-field pa-1" v-on="on">
                            <div v-if="item.color" class="color-disp mx-2" :style="{ 'background-color': item.color ? item.color : '#ffffff' }">
                            </div>
                            <div class="ma-4" v-else>
                              クリックして色を選択して下さい
                            </div>
                          </div>
                        </template>
                        <v-card class="pa-4 text-center">
                          <v-color-picker
                            hide-details
                            hide-canvas
                            hide-sliders
                            hide-inputs
                            :value="item.color ? item.color : '#ffffff'"
                            @input="(v) => changeParking(ind, { color: v })"
                            :swatches="swatches"
                            show-swatches
                          />
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
                <!--Number and colour END-->

                <!--Start and end date of use BEGIN-->
                <div>
                  <FormLabel :label="$t('common.useTheSchedule')" required />

                  <div class="d-flex align-center">
                    <v-select
                      solo
                      hide-details
                      outlined
                      flat
                      class="rounded-lg"
                      :value="getFormattedDate(item.fromDate)"
                      :items="[getFormattedDate(item.fromDate)]"
                      @click="openFromDialog(ind)"
                    />
                    <ModalDated
                      :visible="rentalDateModalsFrom[ind]"
                      @close="openFromDialog(ind, false)"
                      :items="fromDateRange"
                      :value="item.fromDate"
                      @input="(v) => changeParking(ind, { from: v })"
                    />
                    <div class="text-medium --prm --w-bold px-4"> 〜 </div>
                    <v-select
                      :disabled="!item.fromDate"
                      :value="getFormattedDate(item.toDate)"
                      :items="[getFormattedDate(item.toDate)]"
                      @click="openToDialog(ind)"
                      solo
                      outlined
                      flat
                      class="rounded-lg"
                      hide-details
                    />
                    <ModalDated
                      :visible="rentalDateModalsTo[ind]"
                      @close="openToDialog(ind, false)"
                      :items="getToDateRange(ind)"
                      :value="item.toDate"
                      @input="(v) => changeParking(ind, {to: v})"
                    />
                  </div>
                </div>
                <!--Start and end date of use END-->

                <!--Parking lot format BEGIN-->
                <v-radio-group
                  hide-details
                  :value="item.parking && item.parking.id"
                >
                  <v-container>
                    <v-row>
                      <FormLabel :label="$t('reservationAndUsageHistory.parkingLotFormat')" required />
                    </v-row>
                    <v-row
                      class="mb-2 pa-0"
                      v-for="parking in parkings"
                      :key="parking.id"
                    >
                      <v-col
                        class="pr-0 col-12 radio-border "
                        :class="{'bg-gray--light opacity-9': isFull(parking, ind)}"
                      >
                        <v-radio
                          class="py-2 pl-0 pr-1"
                          :disabled="isFull(parking, ind)"
                          :value="parking.id"
                          @change="changeSelectedParking(ind, parking, false)"
                        >
                          <template v-slot:label>
                            <div class="d-flex justify-space-between">
                              <div
                                class="text-medium pr-2 parking-name"
                                :class="{ '--c-super-dark-red': isFull(parking, ind) }"
                              >
                                {{ parking.name }}
                              </div>
                              <h1
                                class="text-medium --prm justify-center flex-column d-flex"
                                :class="{ '--c-tertiary': isFull(parking, ind) }"
                              >
                                ({{ $t('vacancy.vehicleHeightLimit') }}: {{parking.maxHeight ? `${parking.maxHeight}cm` : $t('common.none')}})
                              </h1>
                            </div>
                            <h1
                              v-if="isFull(parking, ind)"
                              class="text-x-heading --white justify-center flex-column d-flex parking-full mx-2"
                            >
                              {{ $t('reservationAndUsageHistory.full') }}
                            </h1>
                          </template>
                        </v-radio>
                      </v-col>
                      <v-col v-if="isFull(parking, ind)" class="pr-0 pt-2 col-12 d-flex">
                        <IconDownRight class="mx-2"/>
                        <v-radio
                          class="py-2 pl-0 pr-1"
                          :label="$t('reservationAndUsageHistory.registerWaitingForCancellation')"
                          :value="parking.id"
                          @change="changeSelectedParking(ind, parking, true)"
                        ></v-radio>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-radio-group>
              </div>
            </template>
          </template>
        </div>
        <!--Parking lot format END-->
      </div>
      <!--Buttons BEGIN-->
      <v-btn
        elevation="0"
        v-if="btn"
        rounded
        block
        height="66"
        color="bg-primary"
        class="text-title --white mb-3"
      >
        {{ $t('reservationAndUsageHistory.changeToTheAboveContent') }}
      </v-btn>
    </div>
    <div v-else-if="this.$slots.notAvailableMessage" class="text-center ma-4">
      <slot name="notAvailableMessage" />
    </div>
  </div>
</template>

<script>
import { addDays, getDateRangeArray, getStayDateRangeArray } from '@/utils/get-date'
import FormLabel from '../Inputs/FormLabel'
import ModalDated from './modal/ModalDated'
import IconDownRight from '@/components/icons/IconDownRight'
import { getCarColorSwatches } from '@/utils/parking'

export default {
  name: 'ChangeParkingInformation',
  components: {
    FormLabel,
    ModalDated,
    IconDownRight
  },
  props: {
    checkInDate: String,
    checkOutDate: String,
    facilityId: Number,
    currentReservationParkingId: Number, // the currently help reservation's parking Id. This is assuming that the user has up-to 1 reservation per booking.
    // if we want to extend this to support multiple reservations per booking, this should be an array or something
    btn: {
      Boolean
    },
    value: {
      type: [Array, Number],
      default: () => []
    },
    change: Boolean
  },
  watch: {
    facilityId: {
      immediate: true,
      async handler () {
        await this.loadParkings()
      }
    },
    checkInDate: {
      async handler () {
        await this.loadParkings()
      }
    },
    checkOutDate: {
      async handler () {
        await this.loadParkings()
      }
    },
    borrow (state) {
      if (state) {
        this.parkingCount = 1
      }
    },
    value: {
      immediate: true,
      handler (value) {
        // if there are bookings already, show it
        let count = 0
        if (typeof value === 'number' && value > 0) {
          this.borrow = true
          count = value
        } else if (Array.isArray(value) && value.length > 0) {
          this.borrow = true
          count = value.length
        }
        if (count !== this.parkingCount) {
          // do this only if it's changed
          this.parkingCount = count
        }
      }
    }
  },
  methods: {
    getFormattedDate (date) {
      if (!date) return ''
      return this.$options.filters.jaShortDate(date)
    },
    openFromDialog (ind, visible = true) {
      this.$set(this.rentalDateModalsFrom, ind, visible)
    },
    openToDialog (ind, visible = true) {
      this.$set(this.rentalDateModalsTo, ind, visible)
    },
    getDefaultRentalPeriod (ind) {
      if (this.forChange) {
        if (this.value[ind]) {
          const { fromDate, toDate } = this.value[ind]
          if (fromDate === this.checkInDate && toDate === this.checkOutDate) {
            return 'whole'
          }
        }
        return 'part'
      }
      return null
    },
    getToDateRange (ind) {
      const { fromDate } = this.value[ind]
      return getDateRangeArray(addDays(fromDate, 1), this.checkOutDate, { excludeLast: false })
    },
    changeParking (ind, {
      from, to, carNumber, color, parking
    }) {
      const copied = this.value.slice()
      const item = copied[ind]
      if (from) { item.fromDate = from }
      if (to) { item.toDate = to }
      if (parking) {
        item.parking = parking
        if (parking.maxHeight) {
          item.height = parking.maxHeight
        }
      }
      if (carNumber) { item.carNumber = carNumber }
      if (color) { item.color = color }

      if (item.fromDate && item.toDate && item.toDate <= item.fromDate) {
        // from date cannot be the same or after toDate. Reset todate to prompt user to re-select
        item.toDate = null
      }
      copied.splice(ind, 1, item)
      this.emitInput(copied)
    },
    emitInput (arr) {
      this.$emit('input', arr)
    },
    toggleBorrow (borrow) {
      if (!borrow) {
        this.emitInput([])
      }
    },
    isFull (p, ind) {
      const { fromDate, toDate } = this.value[ind]
      if (this.currentReservationParkingId === p.id) {
        // this means that the user currently has a reservation in this parking, so it is allowed even if there is not qty left
        return false
        // todo: even in this case, if the fromDate and toDate are changed we must validate it. but for now leave it as is
      }
      const stayDates = getDateRangeArray(fromDate, toDate, { excludeLast: true })
      for (const stayDate of stayDates) {
        const found = p.availabilities.find(a => a.stayDate === stayDate)
        if (!found || found.quantity <= 0) {
          return true
        }
      }
      return false
    },
    changeSelectedParking (ind, parking, waitlist) {
      this.changeParking(ind, {
        parking: {
          ...parking,
          waitlist
        }
      })
    },
    async loadParkings () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('loadParkingsForBooking', {
            facilityId: this.facilityId,
            checkInDate: this.checkInDate,
            checkOutDate: this.checkOutDate
          })
          if (!this.parkings.length) this.$emit('notAvailable')
        })
      })
    }
  },
  data () {
    return {
      borrow: false,
      completionModal: false,
      parkingRadio: [
        { use: this.$t('reservationAndUsageHistory.use'), value: true },
        { use: this.$t('reservationAndUsageHistory.doNotUse'), value: false }
      ],
      rentalDateModalsFrom: [],
      rentalDateModalsTo: []
    }
  },
  computed: {
    parkingCount: {
      get () { return this.value.length },
      set (newLength) {
        if (newLength === 0) this.borrow = false
        if (this.isParkingManaged) {
          if (this.value.length < newLength) {
            this.emitInput(
              this.value.concat([...Array(newLength - this.value.length)].map(() => ({
                fromDate: this.checkInDate,
                toDate: this.checkOutDate
              })))
            )
          } else {
            this.emitInput(
              this.value.slice(0, newLength)
            )
          }
        } else {
          this.$emit('input', newLength)
        }
      }
    },
    isParkingManaged () {
      return this.$store.getters.isParkingManagedFacility
    },
    parkings () { return this.$store.getters.parkingsForBooking },
    fromDateRange () {
      return getStayDateRangeArray(this.checkInDate, this.checkOutDate)
    },
    swatches () {
      return getCarColorSwatches()
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/styles/modules/variables-scss' as v;

::v-deep {
  .v-radio {
    label {
      font-size: var(--font-size__medium);
      color: var(--color__ue-red);
      font-weight: bold !important;
    }
  }
  .rounded-10 {
    border: solid 1px var(--color__primary);
  }
  @media (max-width: v.$breakpoint__x-small - 1) {
    .rounded-10 {
      padding: 12px !important;
    }
  }
}
.opacity-9 {
  opacity: 0.9;
}
.parking-name {
  word-break: break-all;
  max-width: 60%;
}
.parking-full {
  width: 4em;
  text-align: center;
}
</style>
